import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { getSessionUser } from '@util/session';

import * as config from '@config';
import '@config/yup';

import ProtectedRoute from '@router/ProtectedRoute';
import Login from 'Login/Login';
import Chat from 'Ai-Chat/pages/chat/Chat';
import Patient from 'Participants/Patient';
import Participants from 'Participants/Participants';
import UtilizationsTracker from 'Utilization';
import Caregiver from 'Users/Caregiver';
import Caregivers from 'Users/Caregivers';
import Digest from 'Digest';
import Polypharmacy from 'Polypharmacy';
import HomeRenderer from 'Homes';
import Settings from 'Settings/Settings';
import PasswordReset from 'Admin/resetPassword/PasswordReset';
import NotFound from 'Login/NotFound';
import Maintenance from 'Login/Maintenance';
import IntusNavBar from '@intus-ui/components/IntusNavBar';
import HelpButton from 'Help/HelpButton';
import NotificationModal from 'Updates/notification/NotificationModal';
import Admin from 'Admin/admin/Admin';
import ChangeMyFlags from 'Admin/admin/ChangeMyFlags';
import UserTrack from 'Admin/UserTracking/UserTrack';
import Welcome from 'Welcome';
import Initiatives from 'Initiatives';
import Organization from 'Admin/admin/Organization';
import CaregiverSettingsModal from 'Users/caregiver/caregiverList/caregiverSettingsModal';
import PasswordResetModal from 'Users/caregiver/caregiverList/passwordResetModal';
import SuccessModal from 'Users/caregiver/caregiverList/SuccessModal';
import HelpModal from 'Help/HelpFormModal';
import UserSettingPromptsModal from 'Settings/UserSettingPromptsModal';
import PowerBIEmbed from 'powerBI/PowerBIEmbed';
import {
  SUBSCRIPTION_ACUITY,
  SUBSCRIPTION_COHORTS,
  SUBSCRIPTION_DASHBOARD,
  USER_ACCESS_ADMIN,
  USER_ACCESS_GOD,
  USER_ACCESS_TRACKING_ADMIN,
  USER_ACCESS_HEALTH_SYS_ADMIN,
} from 'Settings/userSettingsPropType';
import AdvancedCohort from 'AdvancedCohort';

import 'bootstrap/scss/bootstrap.scss';
import '@intus-ui/styles/BootstrapCustom.scss';
import 'App.css';
import 'animate.css';
import '@intus-ui/styles/Utilities.scss';

import TwoFactorSetup from 'Login/TwoFactorSetup';
import TwoFactorValidation from 'Login/TwoFactorValidation';
import { CustomDashboardRoutes } from 'CustomDashboardV2/CustomDashboardRoutes';
import { SSORedirect } from 'Login/SSORedirect';
import { DashboardWrapper } from 'Dashboard/DashboardWrappers/DashboardWrapper';
import Admits from 'Admits';
import PowerBIReportIndexView from 'powerBI/PowerBiReportIndexPage';

const RedirectToNotFound = () => <Redirect to="/" />;
const RedirectToRoot = () => <Redirect to="/" />;
const RedirectToHome = () => <Redirect to="/home" />;

const App = () => {
  React.useEffect(() => {
    config.override.localStorage();
  });

  const user = getSessionUser();

  return (
    <BrowserRouter>
      <CaregiverSettingsModal />
      <PasswordResetModal />
      <SuccessModal />
      <NotificationModal />
      <HelpModal />
      <div className="App w-100 h-100 d-flex flex-column">
        <Switch>
          <Route exact path="/twoFactorSetup" component={TwoFactorSetup} />
          <Route exact path="/twoFactorValidation" component={TwoFactorValidation} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/maintenance" component={Maintenance} />
          <Route exact path="/" component={user === null ? Login : RedirectToHome} />
          <Route exact path="/sso-redirect" component={SSORedirect} />
          <Route exact path="/signin" component={RedirectToRoot} />
          <Route exact path="/expired" component={RedirectToRoot} />

          <Route
            exact
            path="/resetpassword/:token"
            render={({ match }) => <PasswordReset token={match.params.token} />}
          />
          <Route exact path="/welcome" component={Welcome} />

          <Route path="*">
            <IntusNavBar />
            <HelpButton />
            <UserSettingPromptsModal />
            <div className="w-100 flex-grow-1 overflow-hidden">
              <Switch>
                <ProtectedRoute exact path="/home" component={HomeRenderer} />
                <ProtectedRoute path="/initiatives" component={Initiatives} />
                <ProtectedRoute exact path="/chat" requiredFlag="ai-chat" component={Chat} />
                <ProtectedRoute exact path="/reports/:reportNameParam" component={PowerBIEmbed} />
                <ProtectedRoute
                  exact
                  path="/reports"
                  component={PowerBIReportIndexView}
                />
                <ProtectedRoute
                  path="/cohorts/:tabName?/:cohortId?"
                  component={AdvancedCohort}
                  subscriptionType={SUBSCRIPTION_COHORTS}
                />
                <Route path="/settings/:tabName?/:subPage?" component={Settings} />
                <ProtectedRoute
                  exact
                  path="/patients"
                  component={Participants}
                  subscriptionType={SUBSCRIPTION_ACUITY}
                />
                <ProtectedRoute
                  exact
                  path="/caregivers"
                  component={Caregivers}
                  permittedAccess={[USER_ACCESS_ADMIN, USER_ACCESS_HEALTH_SYS_ADMIN]}
                />
                <ProtectedRoute path="/admits" component={Admits} />
                <ProtectedRoute path="/utilization" component={UtilizationsTracker} />
                <ProtectedRoute
                  path="/dashboard"
                  component={DashboardWrapper}
                  subscriptionType={SUBSCRIPTION_DASHBOARD}
                />
                <ProtectedRoute
                  path="/customDashboards"
                  component={CustomDashboardRoutes}
                  subscriptionType={SUBSCRIPTION_DASHBOARD}
                />
                <ProtectedRoute exact path="/digest" component={Digest} />
                <ProtectedRoute path="/polypharmacy/:tab" component={Polypharmacy} />
                <ProtectedRoute
                  exact
                  path="/admin"
                  component={Admin}
                  permittedAccess={[USER_ACCESS_GOD]}
                />
                <ProtectedRoute
                  exact
                  path="/changeMyOrg"
                  component={Admin}
                  requiredFlag="intus-care-org-changer"
                />
                <ProtectedRoute
                  exact
                  path="/changeMyFlags"
                  component={ChangeMyFlags}
                  requiredFlag="intus-care-org-changer"
                />
                <ProtectedRoute
                  exact
                  path="/userTrack"
                  component={UserTrack}
                  permittedAccess={[USER_ACCESS_GOD, USER_ACCESS_TRACKING_ADMIN]}
                />
                <ProtectedRoute
                  path="/patient/:patientId/:tabName"
                  render={({ match }) => <Patient patientId={match.params.patientId} />}
                  subscriptionType={SUBSCRIPTION_ACUITY}
                />
                <ProtectedRoute
                  path="/patient/:patientId"
                  render={({ match }) => <Patient patientId={match.params.patientId} />}
                  subscriptionType={SUBSCRIPTION_ACUITY}
                />
                <ProtectedRoute
                  path="/caregiver/:userId"
                  render={({ match }) => <Caregiver userId={match.params.userId} />}
                  exact
                  permittedAccess={[USER_ACCESS_ADMIN]}
                />
                <ProtectedRoute
                  path="/admin/:orgId"
                  render={({ match }) => <Organization orgId={match.params.orgId} />}
                  exact
                  permittedAccess={[USER_ACCESS_GOD]}
                />
                <Route component={RedirectToNotFound} />
              </Switch>
            </div>
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
