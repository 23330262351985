import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import * as userTracking from '@util/userTracking';
import { getPowerBiReportsList } from '@api';
import { Divider, MenuItem } from '@mui/material';
import { Text } from '@intus-ui';

const PowerBINavbarOptions = ({ onCloseMenu }) => {
  const [menuOptions, setMenuOptions] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const fetchMenuOptions = async () => {
      try {
        const options = await getPowerBiReportsList();
        setMenuOptions(options);
      } catch (error) {
        // Handle error, e.g., set error state or log the error
        userTracking.logEvent('Failed to fetch Power BI Reports List');
      }
    };

    fetchMenuOptions();
  }, []);

  const addUserTrackingToOptions = (options) => {
    if (options.length === 0) return [];
    return options.map((option) => ({
      ...option,
      onClick: (e) => {
        onClickItem(e, option.reportId);
        userTracking.logEvent(`PowerBI Report: Opened ${option.reportName}`, {
          from: 'Nav',
        });
      },
    }));
  };

  const onClickItem = (e, reportId) => {
    onCloseMenu();
    history.push(`/reports/${reportId}`);
  };

  const optionsWithTracking = addUserTrackingToOptions(menuOptions);

  return (
    <>
      {menuOptions.length > 0 &&
        optionsWithTracking.map((option) => {
          const isActive = location.pathname.includes(option.reportId);
          return (
            <Fragment key={option.reportId}>
              <MenuItem onClick={option.onClick} key={option.reportId} role="link">
                <Link to={`reports/${option.reportId}`}>
                  <Text type="subtitle" color={isActive ? 'navy' : 'link'}>
                    {option.reportName}
                  </Text>
                </Link>
              </MenuItem>
            </Fragment>
          );
        })}
      <Divider style={{ margin: '0px 15px' }} />
      <Fragment key="all">
        <MenuItem onClick={onCloseMenu} key="home" role="link">
          <Link to="/reports">
            <Text type="subtitle" color="link">
              View & Manage Reports
            </Text>
          </Link>
        </MenuItem>
      </Fragment>
    </>
  );
};

export default PowerBINavbarOptions;
