// IntusNavBar.js

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import track from 'react-tracking';
import IdleTimer from 'react-idle-timer';

import { clearSession, getSessionUser } from '@util/session';
import { getFeatureFlag } from '@config';
import { renewAuthToken, getTrackingEvent, getPowerBiReportsList } from '@api/api';
import { resetModal } from '@global-state/redux/homepageSlice';
import { getUpdatedTime, resetModalContent } from '@global-state/redux/dashboardSlice';
import { setPatientList } from '@global-state/redux/profileSlice';

import { formatDate } from '@util/dateFunctions';

import { Navbar } from 'react-bootstrap';
import { Text } from '@intus-ui';
import { clearLocalData } from '@util/localStorage';

import alex from 'assets/alex.png';
import logo from 'assets/logo.svg';

import {
  SUBSCRIPTION_ACUITY,
  SUBSCRIPTION_COHORTS,
  SUBSCRIPTION_DASHBOARD,
  USER_ACCESS_ADMIN,
  USER_ACCESS_GOD,
  USER_ACCESS_HEALTH_SYS_ADMIN,
  USER_ACCESS_TRACKING_ADMIN,
} from 'Settings/userSettingsPropType';

import SessionTimeoutPopup from '../SessionTimeoutPopup';

import '@intus-ui/styles/IntusNavBar.scss';
import '@intus-ui/styles/Utilities.scss';
import '@intus-ui/styles/Settings.scss';

import NavTabs from './NavTabs';
import SettingsDropdown from './SettingsDropdown';
import InternalTabs from './InternalTabs';
import { getTabs, getInternalTabs } from './tabsConfig';

const timeout = 1000 * 60 * 14; // timeout for popup (ms * sec * min)
const renewAuthTokenTime = 1000 * 60 * 14.5; // milliseconds * seconds * mins

const IntusNavBar = ({
  history,
  doResetHomeModal,
  doResetDashModal,
  updatedTime,
  location,
  resetRedux,
  doGetUpdatedTime,
  doResetPatientList,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [pageHover, setPageHover] = useState(undefined);
  const [pbiIsActive, setPbiIsActive] = useState(false);

  const idleTimer = useRef(null);

  useEffect(() => {
    renewAuthToken();
    const intervalId = setInterval(() => renewAuthToken(), renewAuthTokenTime);

    const {
      organization: { id },
    } = getSessionUser() || { organization: { id: undefined } };
    if (id) {
      doGetUpdatedTime(id);
    }
    getPowerBiReportsList().then((response) => {
      if (response.length > 0) {
        setPbiIsActive(true);
      }
    });

    return () => {
      if (intervalId) clearTimeout(intervalId);
    };
  }, [doGetUpdatedTime]);

  const logout = () => {
    clearLocalData();
    resetRedux();
    history.push('/');
  };

  const isActive = (route) => {
    return location.pathname.startsWith(route);
  };

  const session = getSessionUser() || clearSession();
  const access = session?.access;
  const subscriptions = session?.organization?.subscriptions;
  const isGod = access === USER_ACCESS_GOD;
  const isTrackingAdmin = access === USER_ACCESS_TRACKING_ADMIN;
  const isAdmin = access === USER_ACCESS_ADMIN;
  const isDashboardSubscriber = (subscriptions || []).includes(SUBSCRIPTION_DASHBOARD);
  const isAcuitySubscriber = (subscriptions || []).includes(SUBSCRIPTION_ACUITY);
  const isCohortSubscriber = (subscriptions || []).includes(SUBSCRIPTION_COHORTS);

  const { featureIsActive: intusCareOrgChanger } = getFeatureFlag('intus-care-org-changer');
  const { featureIsActive: polypharmacyIsActive } = getFeatureFlag('polypharmacy');
  const { featureIsActive: aiChatisActive } = getFeatureFlag('ai-chat');
  const { featureIsActive: admitsIsActive } = getFeatureFlag('admits');
  const { featureIsActive: initiativesIsActive } = getFeatureFlag('initiatives');
  const { featureIsActive: pbiReportViewOnlyIsActive } = getFeatureFlag('pbi-report-view-only');

  // Check if user is an HSA and has the feature flag.
  const isHealthSystemAdmin = access === USER_ACCESS_HEALTH_SYS_ADMIN;
  const { featureIsActive: hsaUserManagementFlag } = getFeatureFlag('hsa-user-management');
  const hsaUserManagementisActive = isHealthSystemAdmin && hsaUserManagementFlag;

  const pageTabNames = (internalName, displayName, onClick) => {
    const wrappedOnClick = (event) => {
      event.preventDefault();
      onClick(event);
    };
    return (
      <Text
        style={{ padding: '0px 20px', textDecoration: 'none' }}
        id={`tab-${internalName}`}
        color={isActive(`/${internalName}`) || pageHover === internalName ? 'navy' : 'link'}
        type="subtitle"
        wrapper="a"
        onMouseOver={() => setPageHover(internalName)}
        onMouseOut={() => setPageHover(undefined)}
        onClick={wrappedOnClick}
        href={`/${internalName}`}
      >
        {displayName}
      </Text>
    );
  };

  let godSwitchToAdminPage = '/admin';
  if (isCohortSubscriber) godSwitchToAdminPage = '/cohorts';
  if (isAcuitySubscriber) godSwitchToAdminPage = '/patients';
  if (isDashboardSubscriber) godSwitchToAdminPage = '/dashboard';

  const tabsParams = {
    // User access roles
    isGod,
    isTrackingAdmin,
    isAdmin,
    hsaUserManagementisActive,

    // Feature flags
    pbiIsActive,
    polypharmacyIsActive,
    pbiReportViewOnlyIsActive,
    admitsIsActive,
    initiativesIsActive,
    aiChatisActive,

    // Functions
    doResetPatientList,
    doResetHomeModal,
    doResetDashModal,
    getTrackingEvent,

    // Other
    history,
  };

  const tabs = getTabs(tabsParams);

  const internalTabsParams = {
    // User access roles
    isGod,
    godSwitchToAdminPage,

    // Other
    history,
  };
  const internalTabs = getInternalTabs(internalTabsParams);

  const visibleTabs = tabs.filter((tab) => tab.visible);

  return (
    <>
      <Navbar
        className="box-shadow-navbar d-flex justify-content-center py-1 px-3"
        style={{
          backgroundColor: 'white',
          zIndex: 98,
        }}
      >
        <div className="d-flex flex-grow-1">
          <Navbar.Brand className="d-flex flex-row align-items-center h-100 pl-2" href="/home">
            <img alt="IntusCare logo" src={logo} height="30px" />
            {isGod ? <img className="god-himself" src={alex} alt="God" /> : null}
            <div className="d-flex flex-row align-items-center pl-1">
              <h6 className="text-dark font-weight-normal">Intus</h6>
              <h6
                className="text-primary"
                style={{ fontWeight: '600', letterSpacing: 'calc(15px * -0.02)' }}
              >
                {isGod ? 'God' : 'Care'}
              </h6>
              {isGod ? <img className="god-himself" src={alex} alt="God" /> : null}
              {updatedTime && (
                <h6 className="m-0 pl-3 py-0 text-muted">
                  <b>Updated: </b>
                  {formatDate(updatedTime, ' MM/DD, h:mm a ')}
                </h6>
              )}
            </div>
          </Navbar.Brand>
        </div>

        <div className="d-flex flex-row align-items-center">
          {isGod || isTrackingAdmin ? (
            <InternalTabs internalTabs={internalTabs} isActive={isActive} />
          ) : (
            <>
              <NavTabs
                tabs={visibleTabs}
                isActive={isActive}
                pageHover={pageHover}
                setPageHover={setPageHover}
                pageTabNames={pageTabNames}
              />
              <SettingsDropdown
                intusCareOrgChanger={intusCareOrgChanger}
                pageTabNames={pageTabNames}
                history={history}
                doResetPatientList={doResetPatientList}
                getTrackingEvent={getTrackingEvent}
                logout={logout}
              />
            </>
          )}
        </div>
      </Navbar>

      <IdleTimer
        ref={idleTimer}
        onAction={() => idleTimer.current.reset()}
        onIdle={() => setShowModal(true)}
        timeout={timeout}
        events={['mousedown']}
      />
      <SessionTimeoutPopup
        showModal={showModal}
        handleClose={() => {
          setShowModal(false);
          idleTimer.current.reset();
        }}
        logout={logout}
        expired={() => clearSession()}
      />
    </>
  );
};

IntusNavBar.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  doResetDashModal: PropTypes.func.isRequired,
  doResetHomeModal: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  resetRedux: PropTypes.func.isRequired,
  doGetUpdatedTime: PropTypes.func.isRequired,
  doResetPatientList: PropTypes.func.isRequired,
  updatedTime: PropTypes.string,
};

IntusNavBar.defaultProps = {
  updatedTime: null,
};

const mapState = (state) => ({
  updatedTime: state.dashboard.updatedTime,
});

const mapDispatch = (dispatch) => ({
  resetRedux: () => dispatch({ type: 'USER_LOGOUT' }),
  doResetHomeModal: () => dispatch(resetModal()),
  doResetDashModal: () => dispatch(resetModalContent([])),
  doGetUpdatedTime: (organizationId) => dispatch(getUpdatedTime(organizationId)),
  doResetPatientList: () => dispatch(setPatientList(undefined)),
});

export default track({ page: 'navbar' })(withRouter(connect(mapState, mapDispatch)(IntusNavBar)));
