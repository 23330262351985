/* eslint-disable react/forbid-prop-types */
// NavTabs.js

import React from 'react';
import PropTypes from 'prop-types';
import MenuItemDropdown from '../MenuItemDropdown';
import SearchBar from './SearchBar';

const NavTabs = ({ tabs, isActive, pageHover, setPageHover, pageTabNames }) => {
  return (
    <>
      {tabs.map((tab) =>
        tab.isDropdown ? (
          <div
            key={tab.key}
            onFocus={() => setPageHover(tab.key)}
            onMouseOver={() => setPageHover(tab.key)}
            onMouseOut={() => setPageHover(undefined)}
            onBlur={() => setPageHover(undefined)}
          >
            <MenuItemDropdown
              title={tab.label}
              id={`tab-${tab.key}`}
              isActive={isActive(tab.path)}
              isHovered={pageHover === tab.key}
              menuItems={tab.menuItems}
            />
          </div>
        ) : (
          pageTabNames(tab.key, tab.label, tab.onClick)
        )
      )}
      <SearchBar />
    </>
  );
};

NavTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  isActive: PropTypes.func.isRequired,
  pageHover: PropTypes.string,
  setPageHover: PropTypes.func.isRequired,
  pageTabNames: PropTypes.func.isRequired,
};

export default NavTabs;
