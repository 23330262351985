// tabsConfig.js

import { getStringReplacement } from '@util/stringReplacements';
import { getTrackingEvent } from '@api/api';
import { formatDate, newDate, toUTC } from '@util/dateFunctions';
import PowerBINavbarOptions from 'powerBI/PowerBINavbarOptions';
import { getSessionUser, setSessionUser } from '@util/session';
import DashboardMenuOptions from './DashboardMenuOptions';

export const getTabs = (params) => {
  const {
    // User access roles
    isGod,
    isTrackingAdmin,
    pbiIsActive,
    isAdmin,
    hsaUserManagementisActive,

    // Functions
    doResetPatientList,
    doResetHomeModal,
    doResetDashModal,

    // Feature flags
    polypharmacyIsActive,
    admitsIsActive,
    initiativesIsActive,
    aiChatisActive,
    pbiReportViewOnlyIsActive,

    // Other
    history,
  } = params;

  return [
    // HOME
    {
      key: 'home',
      label: 'Home',
      path: '/home',
      visible: !isGod && !isTrackingAdmin && !pbiReportViewOnlyIsActive,
      onClick: () => {
        doResetPatientList();
        doResetHomeModal();
        doResetDashModal();
        getTrackingEvent({
          time: formatDate(toUTC(newDate())),
          page: 'Home Page',
          action: 'Home page loaded',
        });
        history.push('/home');
      },
    },
    // ADMITS
    {
      key: 'admits',
      label: 'Admits',
      path: '/admits',
      visible: !isGod && !isTrackingAdmin && admitsIsActive && !pbiReportViewOnlyIsActive,
      onClick: () => {
        getTrackingEvent({
          time: formatDate(toUTC(newDate())),
          page: 'Admits',
          action: 'Admits page loaded',
        });
        history.push('/admits');
      },
    },
    // INITIATIVES
    {
      key: 'initiatives',
      label: 'Initiatives',
      path: '/initiatives',
      visible: !isGod && !isTrackingAdmin && initiativesIsActive && !pbiReportViewOnlyIsActive,
      onClick: () => {
        history.push('/initiatives');
      },
    },
    // REPORTS
    {
      key: 'reports',
      label: 'Reports',
      path: '/reports',
      visible: !isGod && !isTrackingAdmin && pbiIsActive,
      isDropdown: true,
      menuItems: PowerBINavbarOptions,
    },
    // COHORTS
    {
      key: 'cohorts',
      label: 'Cohorts',
      path: '/cohorts/personal',
      visible: !isGod && !isTrackingAdmin && !pbiReportViewOnlyIsActive,
      onClick: () => {
        doResetPatientList();
        getTrackingEvent({
          time: formatDate(toUTC(newDate())),
          page: 'Cohorts',
          action: 'Cohorts page loaded',
        });
        history.push('/cohorts/personal');
      },
    },
    // PARTICIPANTS
    {
      key: 'patients',
      label: getStringReplacement('Participant', { plural: true }),
      path: '/patients',
      visible: !isGod && !isTrackingAdmin && !pbiReportViewOnlyIsActive,
      onClick: () => {
        doResetPatientList();
        getTrackingEvent({
          time: formatDate(toUTC(newDate())),
          page: 'Participants',
          action: 'Participants page loaded',
        });
        history.push('/patients');
      },
    },
    // DASHBOARDS
    {
      key: 'dashboard',
      label: 'Dashboards',
      path: '/dashboard',
      visible: !isGod && !isTrackingAdmin && !pbiReportViewOnlyIsActive,
      isDropdown: true,
      menuItems: DashboardMenuOptions,
    },
    // POLYPHARMACY
    {
      key: 'polypharmacy',
      label: 'Medications',
      path: '/polypharmacy/first-fills',
      visible: !isGod && !isTrackingAdmin && polypharmacyIsActive && !pbiReportViewOnlyIsActive,
      onClick: () => {
        getTrackingEvent({
          time: formatDate(toUTC(newDate())),
          page: 'Polypharmacy Page',
          action: 'Polypharmacy page loaded',
        });
        history.push('/polypharmacy/first-fills');
      },
    },
    // USERS
    {
      key: 'caregivers',
      label: 'Users',
      path: '/caregivers',
      visible: !isGod && !isTrackingAdmin && (isAdmin || hsaUserManagementisActive),
      onClick: () => {
        doResetPatientList();
        getTrackingEvent({
          time: formatDate(toUTC(newDate())),
          page: 'Users',
          action: 'Users page loaded',
        });
        history.push('/caregivers');
      },
    },
    // AI CHAT
    {
      key: 'chat',
      label: 'Chat',
      path: '/chat',
      visible: !isGod && !isTrackingAdmin && aiChatisActive && !pbiReportViewOnlyIsActive,
      onClick: () => {
        getTrackingEvent({
          time: formatDate(toUTC(newDate())),
          page: 'Chat',
          action: 'Chat page loaded',
        });
        history.push('/chat');
      },
    },
  ];
};

export const getInternalTabs = (params) => {
  const { isGod, godSwitchToAdminPage, history } = params;

  return [
    // SWITCH TO ADMIN VIEW
    {
      key: 'switchToAdmin',
      label: 'Switch to Admin view',
      path: godSwitchToAdminPage,
      visible: true,
      onClick: () => {
        const user = getSessionUser();
        user.access = 'ADMIN';
        setSessionUser(user);
        history.push(godSwitchToAdminPage);
      },
    },
    // ORGANIZATIONS (for God users)
    {
      key: 'admin',
      label: 'Organizations',
      path: '/admin',
      visible: isGod,
      onClick: () => {
        history.push('/admin');
      },
    },
    // TRACKING
    {
      key: 'userTrack',
      label: 'Tracking',
      path: '/userTrack',
      visible: true,
      onClick: () => {
        history.push('/userTrack');
      },
    },
  ];
};
