import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import authenticateRoute from '@util/authenticateRoute';
import { clearSession } from '@util/session';

/* This component takes in the same props that a Route component would but validates or
 * not a user has authentication to visit the desired Route based on org subscriptions
 */
const ProtectedRoute = ({
  component: Component,
  render,
  subscriptionType,
  permittedAccess,
  requiredFlag,
  ...rest
}) => {
  const isAuthenticated = authenticateRoute({
    subscriptionType,
    permittedAccess,
    requiredFlag,
  });
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          clearSession();
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        if (Component !== undefined) return <Component {...props} />;
        return render(props);
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  render: PropTypes.func,
  subscriptionType: PropTypes.string,
  permittedAccess: PropTypes.instanceOf(Array),
};

ProtectedRoute.defaultProps = {
  component: undefined,
  render: undefined,
  subscriptionType: undefined,
  permittedAccess: undefined,
};

export default ProtectedRoute;
