import React, { useState, useEffect } from 'react';
import { getPowerBiReportsList, getReportUsers, getElligibleReportUsers } from '@api';
import { Button, SpinnerOrError, Text, Card, layout } from '@intus-ui';

import AddUsersToReportModal from './addUsersToReportModal';
import EditReportUsersModal from './EditReportUsersModal';

const NonAdminListItem = ({ report }) => (
  <Card fluid>
    <div style={styles.listItemContainer}>
      <Text type="title">{report.reportName}</Text>
      <div style={styles.buttonContainer}>
        <Button
          name="Open Report"
          onClick={() => window.open(`/reports/${report.reportId}`, '_self')}
        />
      </div>
    </div>
  </Card>
);
const AdminListItem = ({ report, openAddUsersModal, openEditReportUsersModal }) => (
  <Card fluid>
    <div style={styles.listItemContainer}>
      <Text type="title">{report.reportName}</Text>
      {!report.isWholeOrgViewable && (
        <div style={styles.buttonContainer}>
          <Button
            name="Open Report"
            onClick={() => window.open(`/reports/${report.reportId}`, '_self')}
          />
          <Button name="Add Users" onClick={() => openAddUsersModal(report)} />
          <Button name="Edit Users" secondary onClick={() => openEditReportUsersModal(report)} />
        </div>
      )}
      {report.isWholeOrgViewable && (
        <Text type="caption">
          This report is viewable by all users in your org. Contact Intus Support to make private.
        </Text>
      )}
    </div>
  </Card>
);

const PowerBIReportIndexView = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  // create a state variable for the users of the report, which we will call the api to get when a report is selected and pass to the modal
  const [reportUsers, setReportUsers] = useState([]);
  // create a state variable for the users of the report, which we will call the api to get when a report is selected and pass to the modal
  const [elligibleUsers, setElligibleUsers] = useState([]);
  const [showEditReportUsersModal, setShowEditReportUsersModal] = useState(false);

  useEffect(() => {
    getPowerBiReportsList()
      .then((response) => {
        setReports(response);
        setLoading(false);
      })
      .catch((_) => {
        setLoading(false);
      });
  }, []);

  const openAddUsersModal = (report) => {
    setSelectedReport(report);
    // call the api to get the elligible users of the report and set the state variable
    getElligibleReportUsers(report.reportId)
      .then((response) => {
        setElligibleUsers(response);
      })
      .then(() => {
        setShowAddUsersModal(true);
      });
  };

  const openEditReportUsersModal = (report) => {
    setSelectedReport(report);
    getReportUsers(report.reportId)
      .then((response) => {
        setReportUsers(response);
      })
      .then(() => {
        setShowEditReportUsersModal(true);
      });
  };

  return (
    <div style={styles.mainContainer}>
      {loading && <SpinnerOrError />}
      {!loading && reports.length > 0 ? (
        reports.map((report) =>
          report.permissionType === 'ADMIN' ? (
            <AdminListItem
              key={report.id}
              report={report}
              openAddUsersModal={openAddUsersModal}
              openEditReportUsersModal={openEditReportUsersModal}
            />
          ) : (
            <NonAdminListItem key={report.id} report={report} />
          )
        )
      ) : (
        <div style={layout.centeredContentContainer}>
          <Text>No reports found</Text>
        </div>
      )}
      <AddUsersToReportModal
        show={showAddUsersModal}
        onHide={() => setShowAddUsersModal(false)}
        report={selectedReport}
        // create dummy users for the modal
        users={elligibleUsers}
      />
      <EditReportUsersModal
        show={showEditReportUsersModal}
        onHide={() => setShowEditReportUsersModal(false)}
        report={selectedReport}
        users={reportUsers}
        setReportUsers={setReportUsers}
      />
    </div>
  );
};

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 20,
    gap: 10,
    overflowY: 'auto',
    height: '100%',
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    display: 'flex',
    gap: 20,
  },
};

export default PowerBIReportIndexView;
