/* eslint-disable react/forbid-prop-types */
// InternalTabs.js

import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const InternalTabs = ({ internalTabs, isActive }) => {
  return (
    <>
      {internalTabs
        .filter((tab) => tab.visible)
        .map((tab) => (
          <Nav.Link
            key={tab.key}
            to={tab.path}
            as={Link}
            disabled={isActive(tab.path)}
            className={isActive(tab.path) ? 'text-secondary' : null}
            onClick={tab.onClick}
          >
            <h5 className="font-weight-bold">{tab.label}</h5>
          </Nav.Link>
        ))}
    </>
  );
};

InternalTabs.propTypes = {
  internalTabs: PropTypes.array.isRequired,
  isActive: PropTypes.func.isRequired,
};

export default InternalTabs;
