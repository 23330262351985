/* eslint-disable react/forbid-prop-types */
// SettingsDropdown.js

import React from 'react';
import PropTypes from 'prop-types';
import { NavDropdown } from 'react-bootstrap';
import { Icon } from '@intus-ui';
import { formatDate, newDate, toUTC } from '@util/dateFunctions';

const SettingsDropdown = ({
  intusCareOrgChanger,
  pageTabNames,
  history,
  doResetPatientList,
  getTrackingEvent,
  logout,
}) => {
  return (
    <div className="dropdown-settings d-flex flex-column">
      <NavDropdown
        title={<Icon name="gear" />}
        id="dropdown-settings"
        onMouseEnter={() => document.getElementById('dropdown-settings').click()}
        onMouseLeave={() => document.getElementById('dropdown-settings').click()}
      >
        <div className="d-flex flex-column align-items-start">
          {/* INTUS USERS */}
          {intusCareOrgChanger && (
            <>
              <div className="pt-2">
                {pageTabNames('changeMyOrg', 'Change Org', () => {
                  history.push('/changeMyOrg');
                })}
              </div>
              <div className="py-2">
                {pageTabNames('changeMyFlags', 'Change Flags', () => {
                  history.push('/changeMyFlags');
                })}
              </div>
            </>
          )}
          <div className="pt-2">
            {pageTabNames('settings', 'Settings', () => {
              doResetPatientList();
              getTrackingEvent({
                time: formatDate(toUTC(newDate())),
                page: 'Settings',
                action: 'Settings page loaded',
              });
              history.push('/settings');
            })}
          </div>
          <div className="py-2">
            {pageTabNames('logout', 'Log Out', () => {
              getTrackingEvent({
                time: formatDate(toUTC(newDate())),
                page: '',
                action: 'Logout action',
              });
              doResetPatientList();
              logout();
            })}
          </div>
        </div>
      </NavDropdown>
    </div>
  );
};

SettingsDropdown.propTypes = {
  intusCareOrgChanger: PropTypes.bool.isRequired,
  pageTabNames: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  doResetPatientList: PropTypes.func.isRequired,
  getTrackingEvent: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default SettingsDropdown;
